.wrapper {
  width: 100%;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.fullwidth {
  label {
    width: 100%;
  }
}

// ul.sidebar-nav .nav-group-toggle {
//   font-weight: 700 !important;
//   font-size: 17px;
// }

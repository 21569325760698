// Here you can add other styles
.cursor {
  &:hover {
      background-color: #b4b3bd;
      div{
        background-color: #b4b3bd;}
      svg{
        background-color: #b4b3bd;
      }
    }
  cursor: pointer;
}

.card.hover {
  &:hover {
      background-color: #b4b3bd;
  
      div {
        background-color: #b4b3bd;
      }
  
      svg {
        background-color: #b4b3bd;
      }
    }
  
    cursor: pointer;
}

.form-switch {
  cursor: pointer;
}

.page-item {
  .page-link {
    cursor: pointer;
  }
}

.NoPointer table > tbody {
  cursor: default !important;

  tr {
    &.p-0 {
      background-color: #fff;
    }
    &:hover:not(.p-0) {
      background-color: $gray-300;
    }
  }
}

.Menu {
  background-color: $FrontofficeGreen;
  color: #fff !important;
}

.Menu .sidebar-nav .nav-link.active {
  color: #fff !important;
}

.Menu .sidebar-nav-link:hover,
.Menu .sidebar-nav-dropdown-toggle:hover {
  background-color: #014636;
}

.login {
  background-color: #b4b3bd;
}

// .Logout {
//   height: 65px;
//   width: 65px;
//   text-align: center;
//   position: absolute;
//   padding-top: 5px;
//   right: 0;
//   top: 0;
//   font-size: 33px;
//   cursor: pointer;
//   svg {
//     width: 27px !important;
//     height: 27px !important;
//   }
// }

.noRadius {
  border-radius: 0;
}

.submitSuccess {
  position: relative;
  padding-right: 38px;
  svg {
    top: 9px;
    // margin-right: 18px;
    position: absolute;
    right: 8px !important;
    animation: grow 0.5s;
  }
}

@keyframes grow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.schema {
  &.is-invalid {
    border-radius: 6px;
    border: 1px solid;
    border-color: #ff6f79;
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

.bg-danger-light {
  background-color: #ffe2e4;
}

.devheader {
  position: fixed;
  height: 3px;
  background-color: red;
  width: 100%;
  z-index: 80000;

  .title {
    background-color: red;
    color: #fff;
    text-align: center;
    padding: 2px;
    width: 100px;
    font-size: 10px;
    margin-left: calc(50% - 50px);
    border-radius: 0 0 4px 4px;
  }
}

.modal {
  &.connection {
    z-index: 1103 !important;
    .modal-backdrop {
      z-index: 1055 !important;
    }
  }
}

.ConnectionModal {
  z-index: 2000;
  + .modal-backdrop {
    z-index: 1090;
  }
}

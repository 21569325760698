.ITL-quill {
  .quill {
    .ql-toolbar {
      display: block;
      background-color: $white;
      border-top-left-radius: 0.5em;
      border-top-right-radius: 0.5em;
    }

    .ql-container {
      border-bottom-left-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
      background: #fefcfc;
    }

    &.is-invalid {
      border-radius: 6px;
      border: 1px solid;
      border-color: #ff6f79;
      background-repeat: no-repeat;
    }
  }
}

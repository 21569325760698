.DawaInputAC {
  position: relative;
  //   background-color: deeppink;
  //   padding: 50px;

  .badgeIndicator {
    position: absolute;
    margin-top: 10px;
    right: 51px;
  }
  .dropdown {
    position: absolute;
    top: 45px;
    border-radius: 8px;
    border: 1px solid var(--cui-input-border-color, #ced4da);
    width: 100%;
    z-index: 1000;
    overflow: hidden;
    overflow-y: auto;
    .badge {
      position: relative;
      margin-top: 2px;
      float: right;
    }
    .address:hover {
      background-color: $gray-400 !important;
      cursor: pointer;
    }
  }
}

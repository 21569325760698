.bg-login {
  background: rgb(0, 195, 179);
  background: -moz-radial-gradient(
    circle,
    rgba(0, 195, 179, 0.7091211484593838) 0%,
    rgba(0, 195, 179, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(0, 195, 179, 0.7091211484593838) 0%,
    rgba(0, 195, 179, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(0, 195, 179, 0.7091211484593838) 0%,
    rgba(0, 195, 179, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00c3b3",endColorstr="#00c3b3",GradientType=1);

  .card-group {
    -webkit-box-shadow: 0px 0px 29px 0px rgba(255, 255, 255, 0.31);
    -moz-box-shadow: 0px 0px 29px 0px rgba(255, 255, 255, 0.31);
    box-shadow: 0px 0px 29px 0px rgba(255, 255, 255, 0.31);
  }
}
